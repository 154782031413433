<template>
  <div class="page-validation">
    <div class="container">
      <div v-if="this.hasOfflineQuery" class="offline-warning">
        <p v-html="$t('validation.info_offline')" />
      </div>

      <h1>{{ $t("validation.title", { name: currentUser.nom })}}</h1>
      <div class="results"
        v-for="(dogs, raceId) in getSummary" :key="raceId">
<!--        <h2 v-html="getRaceLabel(getRaceById(raceId))"></h2>-->
        <div class="header">
          <div :title="$t('validation.header_race')">{{ $t("validation.header_race") }}</div>
          <div :title="$t('validation.header_sexe')">{{ $t("validation.header_sexe") }}</div>
          <div :title="$t('validation.header_classe')">{{ $t("validation.header_classe") }}</div>
          <div :title="$t('validation.header_nr_cat')">{{ $t("validation.header_nr_cat") }}</div>
          <div
        :title="$t('validation.header_classement')">{{ $t("validation.header_classement") }}</div>
          <div
      :title="$t('validation.header_qualifier_id')">{{ $t("validation.header_qualifier_id") }}</div>
          <div :title="$t('validation.header_rewards')">{{ $t("validation.header_rewards") }}</div>
          <div
          :title="$t('validation.header_comments')">{{ $t("validation.header_comments") }}</div>
        </div>
        <div class="body">
          <div class="dog" v-for="d in dogs" :key="d.nr_cat">
            <div class="race_id" v-html="getRaceLabel(getRaceById(raceId))" />
            <div class="sex" v-html="getSexLabel(d)" />
            <div class="classe_id" v-html="d.classe_id" />
            <div class="nr_cat" v-html="d.nr_cat" />
            <div class="classement" v-html="getRanking(d)" />
            <div class="qualificatif_id" v-html="getQualifierLabel(d)" />
            <div class="recompenses" v-html="getRewardsLabel(d)" />
            <div class="commentaires" v-html="d.commentaire" />
          </div>
        </div>
      </div>
      <div v-if="!has_data_to_validate">{{ $t('validation.missing_data')}}</div>

      <div v-if="!this.isValidated && !signature_has_been_sent && has_data_to_validate ">
        <hr />
        <h2 v-html="$t('validation.header')"></h2>
        <p v-html="$t('validation.confirmation')" />

        <h2 v-html="$t('validation.signature')" />
        <signature :signature_id="'signature_juge'" ref="signature_juge"
          :original_image="stored_signature" :has_existing="stored_signature !== null"
          @save-signature="onSaveSignature" />
      </div>
      <div class="information" v-if="this.isValidated">
        <p v-html="$t('validation.info_validated')" />
      </div>
    </div>

    <div v-if="signature_has_been_sent" class="thanks">
      <p v-html="$t('validation.bravo')" />
    </div>
    <div v-if="an_error_occured" class="thanks">
      <p v-html="$t('validation.error')" />
    </div>
    <div class="actions" v-if="! this.isValidated &&
    stored_signature !== '' && !signature_has_been_sent && has_data_to_validate">
      <h4 v-if="!signature_defined">{{ $t('signature.signature_required') }}</h4>
      <button
        class="info action"  @click="onSaveOnDevice">{{ $t('validation.save_local')}}</button>

      <button
        :disabled="!signature_defined || hasOfflineQuery"
        :title="!signature_defined ? $t('signature.signature_required') : ''"
        class="submit action"  @click="onValidate">{{ $t('validation.submit')}}</button>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import html2canvas from 'html2canvas';

import Signature from '@/components/Signature.vue';
import { VALIDATE_NOTATIONS, CHANGE_SIGNATURE } from '@/store/const/actions.type';

import dogMixin from '../common/dog.mixin';
import errorMixin from '../common/errors.mixin';

const MAX_WIDTH = 1500;
const MAX_HEIGHT = 2500;
const MIME_TYPE = 'image/jpeg';
const QUALITY = 0.95;

export default {
  components: { Signature },
  mixins: [dogMixin, errorMixin],
  name: 'rewards',
  data() {
    return {
      signature_defined: false,
      signature_has_been_sent: false,
      an_error_occured: false,
    };
  },
  computed: {
    ...mapGetters([
      'getSummary',
      'getRaceById',
      'currentLang',
      'getSignature',
      'getNrCatBoundaries',
      'currentUser',
      'getClassByLabel',
      'getQualifierById',
      'isValidated',
      'isOffline',
      'hasOfflineQuery',
    ]),
    has_data_to_validate() {
      return Object.keys(this.getSummary).length > 0;
    },
    stored_signature: {
      get() {
        return this.getSignature;
      },
      set() {},
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    dogsByClassAndResults() {
      return this.getDogsByClassAndSex(this.id, this.currentLang);
    },
    getQualifierLabel(dog) {
      const qualif = this.getQualifierById(dog.classe_id, dog.qualificatif_id);
      if (!qualif) {
        return '';
      }
      if (this.currentLang === 'fr') {
        return qualif.lib_fr;
      }
      return qualif.lib_en;
    },
    getRanking(dog) {
      if (!dog.classement) {
        return '';
      }
      if (dog.classement === '1') {
        return this.currentLang === 'fr' ? '1er' : '1st';
      }
      if (dog.classement === '2') {
        return this.currentLang === 'fr' ? '2ème' : '2nd';
      }
      if (dog.classement === '3') {
        return this.currentLang === 'fr' ? '3ème' : '3rd';
      }
      return this.currentLang === 'fr' ? `${dog.classement}e` : `${dog.classement}th`;
    },
    getRewardsLabel(dog) {
      if (!dog.recompenses || dog.recompenses.length === 0) {
        return '';
      }
      return dog.recompenses.map((r) => this.getRewardLabel(r)).join(', ');
    },
    getSexLabel(dog) {
      return dog.nr_sexe === 1 ? 'M' : 'F';
    },

    onSaveSignature(imgData) {
      this.signature_defined = true;
      this.$store.dispatch(CHANGE_SIGNATURE, imgData);
    },
    onTestError() {

    },
    async onValidate() {
      if (!window.confirm(this.$t('validation.confirmation_message'))) {
        return;
      }
      // console.log('calling onValidate', html2canvas);
      const canvas = await html2canvas(document.querySelector('.page-validation .container'));
      // html2canvas(document.querySelector('.page-validation .container')).then((canvas) => {
      const dataURL = canvas.toDataURL('image/png');
      // console.log(this.currentUser);

      const boundaries = this.getNrCatBoundaries;

      const resizedImage = await this.resize(dataURL);
      // .then((data) => {
      this.$store.dispatch(VALIDATE_NOTATIONS, {
        img: resizedImage,
        id_juge: this.currentUser.id_juge,
        prem_cat: boundaries.min,
        der_cat: boundaries.max,
        results: this.getSummary,
      }).then((res) => { console.log(res); this.signature_has_been_sent = true; })
        .catch((err) => this.handleError(err));
    },
    async onSaveOnDevice() {
      const jsonData = JSON.stringify(this.getSummary);
      const blob = new Blob([jsonData], { type: 'application/json' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      // hour_min_seconds
      link.download = `jugement_${new Date().toISOString().replace(/:/g, '_')}.json`;

      link.click();
      URL.revokeObjectURL(url);
    },
    getRewardLabel(code) {
      return this.$i18n.t(`rewards.${code}`);
    },
    // Compression
    async resize(rawContent) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = rawContent;
        img.onerror = function (e) {
          URL.revokeObjectURL(e.target.src);
          console.error('Cannot load image');
          reject();
        };
        img.onload = (e) => {
          const image = e.target;
          URL.revokeObjectURL(image.src);
          const [newWidth, newHeight] = this.calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
          const canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          canvas.toBlob(
            (blob) => {
              // Handle the compressed image. es. upload or save in local state
              // displayInfo('Original file', file);
              // displayInfo('Compressed file', blob);
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                console.log('before compression : ', this.readableBytes(image.src.length),
                  'after compression : ', this.readableBytes(reader.result.length));

                resolve(reader.result);
              };
            },
            MIME_TYPE,
            QUALITY,
          );
          // console.log(ctx.getImageData(0, 0, newWidth, newHeight));
        };
      });
    },
    calculateSize(img, maxWidth, maxHeight) {
      let { width, height } = img;

      // calculate the width and height, constraining the proportions
      if (width > height && width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      } else if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }
      return [width, height];
    },
    readableBytes(bytes) {
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      return `${(bytes / (1024 ** i).toFixed(2))} ${sizes[i]}`;
    },
  },
};
</script>

<style lang="scss">
  .page-validation {
    .container {padding:.5rem;}

    .results {border: 1px solid #000; padding: .5rem 0; margin-bottom: 1rem;}
    .body .dog, .header {
      display: grid; grid-template-columns: repeat(8, 1fr);
      border-bottom: 1px solid #ccc; padding: .5rem;
    }
    .header {
      font-weight: bold;
      > * { overflow-x: auto; text-overflow: ellipsis;}
    }

    .summary:not(:empty) {
      display: grid; grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
      .entry {
        display: flex; justify-content: space-between ; border: 1px solid #ccc; padding: .5rem;
      }
      .reward { font-weight: bold; color: #33F; }
    }

    .offline-warning {
      display: block; text-align: center;
      p {
        font-size: .9rem; border: 1px black solid; display: inline-block;
        padding: 1rem; background-color: lavenderblush;
      }
    }
    .thanks {
      margin: 2rem;
      font-size: 2rem;
      text-align: center;
    }

    .information { font-size:1.5rem; text-align: center }

    .actions {
      display:flex; justify-items: center; flex-wrap: wrap; margin-bottom:1rem; gap: 1rem;
      h4 {margin: auto;flex-basis: 100%; text-align: center; margin-bottom: 1rem }
      button {
        margin: auto; width: 400px;
        &:disabled {background-color: #CCC; cursor: not-allowed;}
      }
    }
    button{
      font-weight: 500; padding:1rem 0;
      text-transform: uppercase; line-height: .8rem; font-size: 1rem; border:1px #999 solid;
      &:hover{cursor: pointer; background-color: #B8B9BB}
    }

  }
</style>
