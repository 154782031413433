<template>
  <div class="signature">
    <vue-drawing-canvas
      ref="signature"
      :canvasId="signature_id"
      :image.sync="image"
      :lock="locked"
      v-bind:class="{'hidden': !this.canEditImage}"
    />

    <div class="actions" v-if="canEditImage">

      <div @click.prevent="$refs.signature.reset()" ref="action-restart"
        :title="$t('signature.unlock')" v-if="! locked">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="24" height="24" viewBox="0 0 24 24"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-refresh-cw hover:border-gray-300 hover:border
          hover:cursor-pointer zoom">
          <polyline points="23 4 23 10 17 10"></polyline>
          <polyline points="1 20 1 14 7 14"></polyline>
          <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
        </svg>
        {{  $t('signature.erase') }}
      </div>

      <div @click.prevent="onSaveImage" title="Enregistrer" v-if="!locked">

        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-save hover:border-gray-300 hover:border hover:cursor-pointer zoom">

          <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
          <polyline points="17 21 17 13 7 13 7 21"></polyline>
          <polyline points="7 3 7 8 15 8"></polyline>
        </svg>
        {{ $t('signature.save') }}
      </div>

    </div>
    <template v-if="! canEditImage">
      <img :src="original_image" alt="Signature"/>
      <div class="actions">
        <div @click.prevent="onUnlockImage" ref="action-reset"
          :title="$t('signature.unlock')">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" class="feather feather-unlock">
            <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
            <path d="M7 11V7a5 5 0 0 1 9.9-1"></path>
          </svg>
        </div>
        <div class="message">{{ $t('signature.is_saved') }}</div>
      </div>
    </template>
  </div>
</template>

<script>

// see https://www.vuescript.com/html5-canvas-drawing/
import VueDrawingCanvas from 'vue-drawing-canvas';

export default {
  emits: ['saveSignature'],
  components: { VueDrawingCanvas },
  props: ['signature_id', 'has_existing', 'original_image'],
  data() {
    return {
      locked: false,
      edit_mode: true,
      image: this.original_image,
    };
  },
  computed: {
    canEditImage() {
      return this.edit_mode; // || (this.has_existing === true);
    },
    local_image: {
      get() {
        return this.image;
      },
      set(value) {
        this.$emit('update:image', value);
      },
    },
  },
  methods: {
    onUnlockImage() {
      this.edit_mode = true;
      this.locked = false;
    },
    onSaveImage() {
      if (this.$refs.signature.getAllStrokes().length === 0) {
        return;
      }
      this.$emit('save-signature', this.image); // , JSON.stringify(this.$refs.signature.getAllStrokes()));
      this.locked = true;
      this.edit_mode = false;
    },
  },
};
</script>
<style lang="scss">
  .signature {
    .hidden {display: none}
    canvas, img {
      margin: auto; border: 1px gray solid; display: block; border: solid 1px #000000;
      max-width: 100%;
    }
    @media (max-width: 600px) {
      canvas {overflow: hidden; max-width: none;}
    }
    .actions {
      display: flex; gap: 2rem; margin-top: 1rem; justify-content: center;
      > div:not(.message) {padding:.5rem; font-size: 1.5rem; align-self: center;
        &:hover {background: #CCC; cursor: pointer;}
      }

      .message {font-size: 1.5rem; font-weight: bold; color: #00AA00;}
    }
  }
</style>
